import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="aolis-app">
      
      <div className="navbar-aolis">
        <a href="/" className="navbar-logo-aolis">aolis.org</a>
        {/*<a href="https://discord.gg/vj3QQ79zhb" className="navbar-button-1">
          <i className="fab fa-discord fa-discord-1 fa-2x fa-fw"></i>
        </a>
        <a href="https://www.twitter.com/AolisOrg" className="navbar-button-2">
          <i className="fab fa-twitter fa-twitter-1 fa-2x fa-fw"></i>
        </a>*/}
        {/*<button className="navbar-button-3">Team</button>*/}
        <button className="navbar-button-4">Platforms</button>
        <button className="navbar-button-5">Mission</button>
        <button className="navbar-button-6">
          <i className="fas fa-bars"></i>
        </button>
      </div>

      <div className="aolis-1-div-1">
        <div className="aolis-1-div-1-1">
          <div className="aolis-1-div-1-1-1">
            <p className="text-1-aolis">Join the digital democracy</p>
            <p className="text-2-aolis">Currently our digital lives are managed by a small group of people. We are developing an alternative, an ecosystem of online platforms governed through democracy. Join us and become a member of our growing community!</p>
            <a href="https://discord.gg/vj3QQ79zhb">
              <button className="aolis-1-button-1">Join us on discord</button>
            </a>
          </div>
        </div>
        <div className="aolis-1-div-1-2">
          <div className="aolis-1-div-1-2-1"></div>
          <div className="aolis-1-div-1-2-2"></div>
          <div className="aolis-1-div-1-2-3"></div>
          <div className="aolis-1-div-1-2-4"></div>
          <div className="aolis-1-div-1-2-5"></div>
          <div className="aolis-1-div-1-2-6"></div>
          <div className="aolis-1-div-1-2-7"></div>
          <div className="aolis-1-div-1-2-8"></div>
        </div>
      </div>

      <div className="div-content-2-aolis">
        <p className="p-content-2-aolis-1">Mission</p>
        <p className="p-content-2-aolis-2">Aolis is the first project which aims to provide an ecosystem of online platforms governed through democracy</p>
        <a href="https://discord.gg/vj3QQ79zhb" className="a-content-2-aolis-1">
          <button className="btn-content-2-aolis-1">Join us on discord</button>
        </a>
      </div>

      <div className="div-content-3-aolis">
        <div className="div-content-3-aolis-1">
          <div className="div-content-3-aolis-1-1">
            <div className="div-content-3-aolis-1-2"></div>
            <p className="p-content-3-aolis-1">Democracy is the key</p>
            <p className="p-content-3-aolis-2">We are building the first online platforms governed by a democracy, where the users will have the full power. Join us in this journey towards digital equality</p>
          </div>
        </div>
        <div className="div-content-3-aolis-2">
          <div className="div-content-3-aolis-2-1">
            <div className="div-content-3-aolis-2-2"></div>
            <p className="p-content-3-aolis-1">The ecosystem</p>
            <p className="p-content-3-aolis-2">We will release a metaverse and 8 platforms. Two of these are ecommerce platforms and the betas are online, furthermore soon we will publish the first release of our social media platform</p>
          </div>
        </div>
        <div className="div-content-3-aolis-3">
          <div className="div-content-3-aolis-3-1">
            <div className="div-content-3-aolis-3-2"></div>
            <p className="p-content-3-aolis-1">Contribution</p>
            <p className="p-content-3-aolis-2">In a few weeks our contribution model will be public, and every community member will have the possibility to help us in the creation of this unique concept. Become a pioneer in this digital revolution</p>
          </div>
        </div>
      </div>

      <div className="div-content-4-aolis">
        <p className="p-content-4-aolis-1">The platforms</p>
        <div className="div-content-4-aolis-1">
          <div className="div-content-4-aolis-1-1">
            <p className="p-content-4-aolis-2">ESIVIUM.IO</p>
          </div>
          <div className="div-content-4-aolis-1-2">
            <p className="p-content-4-aolis-3">The democratic metaverse (offline)</p>
            <p className="p-content-4-aolis-4">We don't want a reality for profit, we believe that the virtual reality should be governed through democracy</p>
            <a href="https://aolis.org" className="a-content-4-aolis-1">
              <button className="btn-content-4-aolis-1">Visit</button>
            </a>
          </div>
        </div>
        <div className="div-content-4-aolis-2">
          <div className="div-content-4-aolis-2-1">
            <p className="p-content-4-aolis-2">BUSQUARE.IO</p>
          </div>
          <div className="div-content-4-aolis-2-2">
            <p className="p-content-4-aolis-3-1">Marketplace for services (offline)</p>
            <p className="p-content-4-aolis-4">The first democratic marketplace for services</p>
            <a href="https://aolis.org" className="a-content-4-aolis-1">
              <button className="btn-content-4-aolis-1">Visit</button>
            </a>
          </div>
        </div>
        <div className="div-content-4-aolis-3">
          <div className="div-content-4-aolis-3-1">
            <p className="p-content-4-aolis-2">FREVIO.IO</p>
          </div>
          <div className="div-content-4-aolis-3-2">
            <p className="p-content-4-aolis-3">Ecommerce platform (offline)</p>
            <p className="p-content-4-aolis-4">We want a democratic ecommerce platform, free from the risk of unfair competitive advantages</p>
            <a href="https://aolis.org" className="a-content-4-aolis-1">
              <button className="btn-content-4-aolis-1">Visit</button>
            </a>
          </div>
        </div>
      </div>

      {/*<div className="div-content-5-aolis">
        <p className="p-content-5-aolis-1">Team and contributors</p>
        <div className="div-content-5-aolis-1">
          <div className="div-content-5-aolis-1-1">
            <div className="div-content-5-aolis-1-1-1"></div>
            <p className="p-content-5-aolis-2">Binaryck</p>
            <p className="p-content-5-aolis-3">Founder</p>
            <p className="p-content-5-aolis-4">He started coding 10+ years ago, developing apps and hobby projects. Binaryck previously launched a project called "busquare.com" and part of that service was included in the project Aolis. He believes that ecommerce, social media and virtual reality should be governed through democracy, instead of being managed by tech giants trying to make as much profit as possible on user's time and data. Binaryck decided to start building the solution</p>
          </div>
        </div>
        <div className="div-content-5-aolis-2">
          <div className="div-content-5-aolis-2-1">
            <div className="div-content-5-aolis-2-1-1"></div>
            <p className="p-content-5-aolis-2">Nicolò Bartolomei</p>
            <p className="p-content-5-aolis-3">Developer</p>
            <p className="p-content-5-aolis-4">27, Tesla and technology lover. He is in the IT world from 8 years, mainly for hobby and DIY activities (arduino, raspberry, linux etc). His interest in open source software became a passion when he met Unix. His spare time is spent programming utility software in Python and C#, along with Telegram bots. He worked 2 years for a company which is a home automation products producer</p>
          </div>
        </div>
        <div className="div-content-5-aolis-3">
          <div className="div-content-5-aolis-3-1">
            <div className="div-content-5-aolis-3-1-1"></div>
            <p className="p-content-5-aolis-2-1">MeanGreenGod</p>
            <p className="p-content-5-aolis-3">Community Builder/Moderator - Contributor</p>
            <p className="p-content-5-aolis-4-1">MeanGreenGod is a passionate Community Manager and Moderator with experience in multiple projects. At Aolis, he is the Community Builder and the Moderator of our discord server</p>
          </div>
        </div>
        <div className="div-content-5-aolis-4">
          <div className="div-content-5-aolis-4-1">
            <p className="p-content-5-aolis-2-2">Do you want to become a team member or a contributor?</p>
            <p className="p-content-5-aolis-3-1">Join our Discord server and send a message to Binaryck</p>
            <a href="https://frevio.io" className="a-content-5-aolis-1">
              <button className="btn-content-5-aolis-1">Join us on Discord</button>
            </a>
            <p className="p-content-5-aolis-4-2">What are you waiting for? Become a pioneer of this digital revolution!</p>
          </div>
        </div>
      </div>*/}

      <div className="div-content-6-aolis">
        <div className="div-content-6-aolis-1">
          <p className="p-content-6-aolis-1">Follow all the developments</p>
          {/*<a href="https://www.twitter.com/AolisOrg">
            <i className="fab fa-twitter fa-twitter-2 fa-2x fa-fw"></i>
          </a>*/}
          <a href="https://discord.gg/vj3QQ79zhb">
            <i className="fab fa-discord fa-discord-2 fa-2x fa-fw"></i>
          </a>
        </div>
        <div className="div-content-6-aolis-2">
          <button className="button-content-6-aolis-1">Mission</button>
          <button className="button-content-6-aolis-2">Platforms</button>
          {/*<button className="button-content-6-aolis-3">Team</button>*/}
        </div>
      </div>

      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossOrigin="anonymous"></script>
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossOrigin="anonymous"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossOrigin="anonymous"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossOrigin="anonymous"></script>
    
    </div>
  );
}

export default App;

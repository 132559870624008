import $ from 'jquery';

$(function() {   
  
    $(".navbar-aolis").on('click', '.navbar-button-6', function(event) {
    
      $(".navbar-aolis").append("<div class='div-dropdown-aolis-1'>"+
      "<div class='div-dropdown-aolis-2'>"+
        "<button class='navbar-button-5-1'>Mission</button>"+
        "<button class='navbar-button-4-1'>Platforms</button>"+
        "<button class='navbar-button-3-1'>Team</button>"+
        "<div class='div-dropdown-aolis-3'>"+
            "<a href='https://discord.gg/vj3QQ79zhb' class='navbar-button-1-1'>"+
            "<i class='fab fa-discord fa-discord-1 fa-2x fa-fw'></i>"+
            "</a>"+
            "<a href='https://www.twitter.com/AolisOrg' class='navbar-button-2-1'>"+
            "<i class='fab fa-twitter fa-twitter-1 fa-2x fa-fw'></i>"+
            "</a>"+
        "</div>"+
      "</div></div>"
      );
  
    
    });
    
    $(".navbar-aolis").on('click', '.div-dropdown-aolis-1', function(event) {
    
      var target = $(event.target).attr('class');
  
      if(target == "div-dropdown-aolis-2") {
  
      } else if(target == "div-dropdown-aolis-1") {
  
        $(".div-dropdown-aolis-1").remove();
        $(".div-dropdown-aolis-2").remove();
  
      }
    
    });
  
  });
import $ from 'jquery';

$(function() {   


    //Navbar
    $(".navbar-aolis").on('click', '.navbar-button-5', function(event1) {
    
            $('html, body').animate({
                scrollTop: $(".div-content-2-aolis").offset().top
            }, 2000);
  
    });

    $(".navbar-aolis").on('click', '.navbar-button-4', function(event1) {
    
        $('html, body').animate({
            scrollTop: $(".div-content-4-aolis").offset().top
        }, 2500);
  
    });

    $(".navbar-aolis").on('click', '.navbar-button-3', function(event1) {
    
        $('html, body').animate({
            scrollTop: $(".div-content-5-aolis").offset().top
        }, 2500);
  
    });


    //Footer 
    $(".div-content-6-aolis-2").on('click', '.button-content-6-aolis-1', function(event1) {

            $('html, body').animate({
                scrollTop: $(".div-content-2-aolis").offset().top
            }, 2500);

    });

    $(".div-content-6-aolis-2").on('click', '.button-content-6-aolis-2', function(event1) {
    
        $('html, body').animate({
            scrollTop: $(".div-content-4-aolis").offset().top
        }, 2000);
  
    });

    $(".div-content-6-aolis-2").on('click', '.button-content-6-aolis-3', function(event1) {
    
        $('html, body').animate({
            scrollTop: $(".div-content-5-aolis").offset().top
        }, 2000);
  
    });


    //Dropdown Mobile
    $(".navbar-aolis").on('click', '.navbar-button-5-1', function(event1) {

        $(".div-dropdown-aolis-1").remove();
        $(".div-dropdown-aolis-2").remove();
    
        $('html, body').animate({
            scrollTop: $(".div-content-2-aolis").offset().top
        }, 1500);
  
    });

    $(".navbar-aolis").on('click', '.navbar-button-4-1', function(event1) {

        $(".div-dropdown-aolis-1").remove();
        $(".div-dropdown-aolis-2").remove();
    
        $('html, body').animate({
            scrollTop: $(".div-content-4-aolis").offset().top
        }, 2000);
  
    });

    $(".navbar-aolis").on('click', '.navbar-button-3-1', function(event1) {

        $(".div-dropdown-aolis-1").remove();
        $(".div-dropdown-aolis-2").remove();
    
        $('html, body').animate({
            scrollTop: $(".div-content-5-aolis").offset().top
        }, 2000);
  
    });

});